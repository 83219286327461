<template>
    <div :class="`${size}`">
        <v-card>
            <template v-if="size === 'xs'">
                <v-card-title>
                    Zendesk
                </v-card-title>
                <div>
                    <v-list-item two-line>
                        <v-list-item-content class="py-0" style="align-self:start">
                            <v-list-item-subtitle>Nombre de tickets crées ce mois-ci :</v-list-item-subtitle>
                            <v-list-item-title class="headline font-weight-bold" style="text-align:center;font-size:xxx-large !important">
                                200
                                <v-list-item-subtitle class="pt-0" style="color:green">+20%</v-list-item-subtitle>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'Zendesk',
    props : {
        size : {
            type : String,
            default : 'xs' 
        }
    }
}
</script>

<style lang="scss" scoped>
.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;   
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;
    }
    @media screen and (min-width: 1200px) {
        &.xs {
            grid-column: span 2;
            grid-row: span 2;
        }
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
        &.xmd {
            grid-column: span 8;
            grid-row: span 6;
        }
    }
}
</style>